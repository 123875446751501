@import "~app/mixins";

.graph{
  padding: 5px 8px;
  display: flex;
  position: relative;
}

.title{
  padding-left: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #3C4654;

  &:last-of-type:not(:only-of-type){
    margin-top: 30px;
  }
}

.incomeLabel{
  width: 50%;
  display: inline-block;
}

.donutLegend {
  list-style: none;
  position: relative;
  padding-left: 20px;
  margin-bottom: 15px;
  padding-right: 10px;
  p {
    color: #66707d;
    font-size: 14px;
    font-family: $font-inter;
    font-weight: 500;
    margin: 0;
    span {
      border-left: solid 2px #99a0aa;
      margin: 0 5px;
    }
  }

  &::after {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 999px;
    background: #66707d;
    position: absolute;
    top: 3px;
    left: 0;
  }

  &:nth-of-type(1) {
    &::after {
      background: #D34F0D;
    }
  }
  &:nth-of-type(2) {
    &::after {
      background: #F16722;
    }
  }
  &:nth-of-type(3) {
    &::after {
      background: #F58D5C;
    }
  }
  &:nth-of-type(4) {
    &::after {
      background: #FFC8AC;
    }
  }
  &:nth-of-type(5) {
    &::after {
      background: #0E6673;
    }
  }
  &:nth-of-type(6) {
    &::after {
      background: #128292;
    }
  }
  &:nth-of-type(7) {
    &::after {
      background: #53A6B2;
    }
  }
  &:nth-of-type(8) {
    &::after {
      background: #94C9D1;
    }
  }
  &:nth-of-type(9) {
    &::after {
      background: #DE9C1E;
    }
  }
  &:nth-of-type(10) {
    &::after {
      background: #F4AC21;
    }
  }
  &:nth-of-type(11) {
    &::after {
      background: #F6BA47;
    }
  }
  &:nth-of-type(12) {
    &::after {
      background: #F9D081;
    }
  }
  &:nth-of-type(13) {
    &::after {
      background: #5B68C3;
    }
  }
  &:nth-of-type(14) {
    &::after {
      background: #747FCD;
    }
  }
  &:nth-of-type(15) {
    &::after {
      background: #929BD9;
    }
  }
  &:nth-of-type(16) {
    &::after {
      background: #AFB6E5;
    }
  }
  &:nth-of-type(17) {
    &::after {
      background: #CA1551;
    }
  }
}

.tooltipBg{
  background-color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  border: 1px solid #CCC;
  z-index: 999;
}

.tooltipText{
  font-size: 14px;
  color: #3C4654;
  margin: 0 0;
}

.chart{display: inline-block;}

.css.imageContainer{position: relative; z-index: 10;}

.image{
  position: absolute;
  width: 100px;
  height: 100px;
  top: 105px;
  left: 115px;
  div {
    display: block;
    width: 100%; 
    height: 100%;
  }
  svg {
    width: 100%; 
    height: 100%;
  }
}