@import '~app/variables';

.header {
  font-size: 16px;
  line-height: 18px;
  padding-top: 10px;
  color: $color-blue-dark;
}

.body,
.footer {
  button {
    min-width: 95px;
    margin: 0 6px;
    font-weight: 400;
  }
}

.body {
  margin: 25px 0;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 300px);

  .inputContainer label {
    display: none;
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .description {
      width: 530px;
    }
  }

  table th:first-child {
    width: 220px;
  }

  table td {
    line-height: 26px;
    vertical-align: top;
  }
}

.cellInput {
  input {
    margin-top: 6px;
  }
}

.hiddenCell {
  visibility: hidden;
}

table tr:hover .hiddenCell {
  visibility: visible;
}

.thRow {
  background: #e3e5e8;
  padding: 5px;
  height: 36px;
  margin: 13px -6px 0;
}

.footer button,
.buttonCell {
  line-height: 1;
}

.footer button {
  width: 95px;
}

.footer {
  display: flex;
  justify-content: flex-end;
}
