@import '~app/variables';

.message {
  color: $color-grey;
  font-size: 14px;
  margin-top: 14px;
  margin-bottom: 20px;
  white-space: pre;
}

.buttons {
  display: flex;
  justify-content: space-around;
}
