@import '~app/variables';

.question {
  color: $color-grey;
  text-align: center;
  font-size: 14px;
  margin-top: 14px;
  margin-bottom: 20px;
  white-space: pre-line;

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }
}

.warning {
  color: $color-red;
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  margin-top: 14px;
  margin-bottom: 20px;
  white-space: pre-line;
}

.checks {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.buttons {
  display: flex;
  justify-content: space-around;

  button {
    min-width: 100px;
  }
}
